<template>
    <v-row justify="center">
      <v-dialog
          v-model="shower"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          persistent
      >
      <v-card>
          <v-toolbar :color="color.BG" dark class="pa-0 ma-0" oncontextmenu="return false">
            <v-btn color="primary" icon @click="closedialog()">
              <v-icon :color="color.theme">mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title :style="`color:${color.theme}`">{{ filedata.topic_header }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn  v-if="['xl', 'lg'].includes($vuetify.breakpoint.name)" icon dark @click="fn_printPDF_single(filedata.link_)"><v-icon :color="color.theme">mdi-printer</v-icon></v-btn>
              <v-btn  icon dark><v-icon :color="color.theme" @click="downloadfile(filedata.link_)">cloud_download</v-icon></v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
              <v-layout row wrap
              fill-height
              justify-center
              align-center
              class="pa-0"
              style="height: 90vh; overflow-y:auto;"
            >
            <!-- <v-flex
                v-if="donotpreview === false"
                xs6
                :style="displayloading"
                class="text-center"
              >
                <v-progress-circular
                  size="120"
                  width="8"
                  background-color="light-blue lighten-4"
                  :color="color.theme"
                  indeterminate
                  rounded
                  >กำลังเตรียมไฟล์</v-progress-circular
                >
              </v-flex> -->
            <v-flex xs12 lg12 class="text-center mt-n6">
              <div  class="pt-6 ml-n2 px-n6 pr-0" style="overflow-y:hidden; height: 92vh;">
                  <iframe id="iframeratio" embedded="true" :src="rawbase64" style="height: 90vh; max-width: 99vw; width: 100vw;"></iframe>
                </div>
            </v-flex>
          </v-layout>
          </v-card-text>
      </v-card>
  
  
  
      </v-dialog>
    </v-row>
  </template>
  
  <script>
  import { mapState, mapGetters } from "vuex";
  import gbfGenerate from "@/globalFunctions/generateAuthorize";
  export default {
      props: ["show", "filedata"],
      computed: {
          ...mapState(["username", "authorize", "account_active", "color"]),
          ...mapState({ processloader: "loading" }),
          ...mapGetters([
          "dataUsername",
          "dataAuthorize",
          "dataAccountActive",
          "dataAccesstoken",
          "dataBusinessProfile",
          "dataCitizenProfile",
          "dataDepartmentAccessId",
          "dataAccountId",
          "dataCheckPackage",
          ]),
          shower: {
          get() {
              if (this.show === true) {
                  console.log("FILEDAT ", this.filedata)
                  this.rawbase64 = process.env.VUE_APP_UI+"/extension_pdfjs/viewer.html?file="  + encodeURIComponent(this.filedata.link_);
                  console.log("FILLE RAWBASE ", this.rawbase64)
              }
              return this.show;
          },
          set(value) {
              if (!value) {
              this.closedialog();
              }
          },
          },
          screenSize() {
          switch (this.$vuetify.breakpoint.name) {
              case "xs":
              return 220;
              case "sm":
              return 400;
              case "md":
              return 500;
              case "lg":
              return 600;
              case "xl":
              return 800;
          }
          },
      },
      data: function() {
          return {
              displayloading: "",
              rawbase64: "",
          }
      },
      methods: {
          downloadfile (filedata) {
            window.open(filedata)
          },
          async fn_printPDF_single() {
          console.log("MAII ", this.filedata.link_ )
          let listfile = "";
          this.displayloading = "";
          // this.loading = true;
          let i;
          listfile = "&list_file=" + this.filedata.link_.split('file_id')[1].split('=')[1];
          let auth = await gbfGenerate.generateToken();
          let url =
            // process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "https://onebox.evergreen.co.th/downloads/api/merge_pdf?account_id=" +
            this.dataAccountId +
            "&user_id=" +
            this.dataUsername +
            "&Authorization=" +
            auth.code +
            listfile;
          this.axios.get(url, { responseType: 'blob' }).then((response) => {
              //console.log(response.data.status);
              console.log("Reposne pdf ", response.data)
              if (response.data.type === "application/pdf") {
                this.displayloading = "display: none";
                this.loading = false;
                console.log(this.displayloading);
                //  this.filemultipledownload = [];
                //   this.loaddataprogress_print = false;
                let urlPrint = window.URL.createObjectURL(response.data);
                console.log("URL PRIT ", urlPrint)
                window.open(urlPrint).print();
              } else {
                this.displayloading = "display: none";
                console.log("Errorrrr ")
                
              }
            }).catch((err) => {
            console.log("Errorr Catch ", err)
          })
        // ส่วนที่ไม่ได้ใช้งาน
        // else if (this.filedata.file_type ? (['jpeg', 'jpg', 'png', 'gif', 'tiff'].includes(this.filedata.file_type.toLowerCase())) : (['jpeg', 'jpg', 'png', 'gif', 'tiff'].includes(this.filedata.data_type.toLowerCase()))) {
        //   let auth = await gbfGenerate.generateToken();
        //   let url = process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/convert/file_to_pdf?id=" + this.filedata.file_id;
        //   this.axios.get(url, { responseType: 'blob', headers: { Authorization: auth.code } }).then((response) => {
        //     this.$emit("disloading_print");
        //     if (response.data.type === "application/pdf") {
        //       this.$emit("disloading_print");
        //       let url = window.URL.createObjectURL(response.data);
        //       window.open(url).print();
        //     } else {
        //       this.$emit("disloading_print");
        //       Toast.fire({
        //         icon: "error",
        //         title: this.$t("textprint.filesomethingwrong"),
        //       });
        //     }
        //   });
        // } else {
        //   this.$emit("disloading_print");
        //   Toast.fire({
        //     icon: "error",
        //     title: this.$t("textprint.cannotprint"),
        //   });
        // }
      },
          closedialog() {
            setTimeout(()=>{
            this.rawbase64 = ""
            }, 200)
          this.$emit("closepreviewfile");
          },
      }
  }
  </script>
  
  <style>
  
  </style>